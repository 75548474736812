import * as React from 'react';

import { ErrorType } from 'types/error';

import { ERROR_TYPES } from 'constants/errors';

import { getErrorByType } from 'utils/errors';
import theme from 'utils/theme';

import HeaderLogo from 'components/FilmPage/HeaderLogo';

import {
  Title,
  NoscriptContainer,
  Wrapper,
  ContentWrapper,
  StyledSeparator,
} from '../styled';
import { NoJsDescription } from './styled';

export type JSDisabledProps = {
  errors?: ErrorType[];
};

const JSDisabled = ({ errors }: JSDisabledProps): JSX.Element => {
  const currentError = getErrorByType(errors, ERROR_TYPES.JAVASCRIPT_DISABLED);

  return (
    currentError && (
      <NoscriptContainer>
        <Wrapper>
          <HeaderLogo color={theme.colors.primary.dark} />
          <ContentWrapper>
            <Title>{currentError.title}</Title>
            <NoJsDescription>{currentError.description}</NoJsDescription>
            <StyledSeparator />
          </ContentWrapper>
        </Wrapper>
      </NoscriptContainer>
    )
  );
};

JSDisabled.displayName = 'JSDisabled';

export default JSDisabled;
