import { ErrorType } from 'types/error';

export const getErrorByType = (errors: ErrorType[], errorType: string) => {
  if (!errors) {
    return null;
  }

  const errorResult = errors.find(({ type }) => type === errorType);

  if (!errorResult) {
    return null;
  }

  return errorResult;
};
