import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';

import { isWebGLSupported } from 'utils/platform';

import { ErrorType } from 'types/error';

import useWindowErrors from 'utils/hooks/useWindowErrors';

import NoWebGL from './NoWebGL';

export type ErrorPagesProps = {
  children: ReactNode;
  errors: ErrorType[];
};

const ErrorPages = ({ children, errors }: ErrorPagesProps) => {
  const [jsEnabled, setJsEnabled] = useState(false);

  const { shouldRotate } = useWindowErrors();

  useEffect(() => {
    setJsEnabled(true);
  }, []);

  return (
    <>
      {jsEnabled && !shouldRotate && !isWebGLSupported() && <NoWebGL />}
      {children}
    </>
  );
};

ErrorPages.displayName = 'ErrorPages';

export default ErrorPages;
