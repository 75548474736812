import Separator from 'components/Icons/Separator';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { mediaDesktop, mediaDesktopWide, mediaTablet } from 'utils/responsive';
import { Arapey, NexaBlack } from 'utils/styles/typography';

export const LogoProps = {
  src: '/components/Icons/Logo',
  alt: 'logo',
};

const sharedContainerStyles = css`
  height: 100vh;
  width: 100vw;
  background-color: ${({ theme }) => theme.colors.primary.light};
  overflow: hidden;
  position: fixed;
  top: 0;
  z-index: 1000;
`;

export const NoscriptContainer = styled.noscript`
  ${sharedContainerStyles};
`;

export const Container = styled.div`
  ${sharedContainerStyles};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  height: 100%;
  padding: 10% 0 10% 10%;

  ${mediaTablet(css`
    padding: 5% 0 5% 5%;
  `)}
`;

export const ContentWrapper = styled.div`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Title = styled.div`
  ${NexaBlack};
  font-size: 3rem;
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.colors.primary.dark};
  ${mediaTablet(css`
    font-size: 5rem;
  `)}
  ${mediaDesktopWide(css`
    font-size: 10rem;
  `)}
`;

export const Description = styled.div`
  ${Arapey};
  font-style: italic;
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary.dark};
  ${mediaTablet(css`
    font-size: 2rem;
  `)}
`;

export const Logo = styled.img`
  height: 5rem;
  width: 5rem;
  margin-bottom: 7rem;
  ${mediaTablet(css`
    height: 10rem;
    width: 10rem;
  `)}
  ${mediaDesktopWide(css`
    height: 15rem;
    width: 15rem;
  `)}
`;

export const StyledSeparator = styled(Separator)`
  width: 40vw;
  height: 2px;
  margin-top: 3rem;
  ${mediaDesktop(css`
    width: 13vw;
  `)}
`;

export const ArrowWrapper = styled(motion.div)`
  margin-top: 1rem;
`;
