import React, { useState } from 'react';

import { Button, Info, Wrapper } from './styles';

const Version = () => {
  const [isOpen, setIsOpen] = useState(true);
  const onToggle = () => setIsOpen(state => !state);

  return (
    <Wrapper>
      {isOpen && (
        <Info>{`${process.env.BUILD_NUMBER} (${process.env.ENV})`}</Info>
      )}
      <Button onClick={onToggle}>[ {isOpen ? '-' : '+'} ]</Button>
    </Wrapper>
  );
};

Version.displayName = 'Version';

export default React.memo(Version);
