import { FilmPageContextProvider } from 'context/FilmPageContext';
import React, { useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';

import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import Version from 'u9/components/Version';
import { initGsap } from 'utils/gsap';
import GlobalStyles from 'utils/styles/global-styles';
import theme from 'utils/theme';
import ErrorPages from 'components/ErrorPages';
import { Tracking } from 'utils/tracking';
import smoothscroll from 'smoothscroll-polyfill';
import JSDisabled from 'components/ErrorPages/JSDisabled';

if (process.env.ENV !== 'local') {
  // Sentry.init({
  //   enabled: process.env.NODE_ENV !== 'development',
  //   dsn: process.env.SENTRY_DSN,
  // });
}

initGsap();

const App = ({ Component, pageProps, router }: AppProps) => {
  useEffect(() => {
    if (process.browser) {
      const vh = innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, []);

  useEffect(() => {
    smoothscroll.polyfill();
    Tracking.init();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <FilmPageContextProvider>
        <GlobalStyles />
        <JSDisabled errors={pageProps.errors} />
        {/* @ts-ignore */}
        <AnimatePresence exitBeforeEnter initial={false}>
          <ErrorPages router={router} {...pageProps}>
            <Component key={router.route} router={router} {...pageProps} />
          </ErrorPages>
        </AnimatePresence>

        {process.env.ENV !== 'local' && process.env.ENV !== 'production' && (
          <Version />
        )}
      </FilmPageContextProvider>
    </ThemeProvider>
  );
};

export default App;
