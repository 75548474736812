import { createGlobalStyle } from 'styled-components';
import fonts from 'utils/styles/fonts';

export default createGlobalStyle`
  html,
  body {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
  ::-webkit-scrollbar {
    display: none;
  }
  
  html {
    overflow: hidden;
    scrollbar-width: none;
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: currentColor;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  ${fonts}
`;
