import React from 'react';

interface Props {
  className?: string;
}

const Separator = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 250 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      <path d="M0 1L250 1.00002" stroke="#C4C4C4" strokeWidth="2" />
    </svg>
  );
};

export default Separator;
