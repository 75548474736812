import styled, { css } from 'styled-components';

import { Description } from 'components/ErrorPages/styled';

import { mediaDesktop, mediaTablet } from 'utils/responsive';

export const NoJsDescription = styled(Description)`
  width: 17rem;

  ${mediaTablet(css`
    width: 25rem;
  `)}

  ${mediaDesktop(css`
    width: 35rem;
  `)}
`;
