import { css } from 'styled-components';

export default css`
  @font-face {
    font-family: 'Arapey';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/Arapey-Regular_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Arapey';
    font-weight: 400;
    font-style: italic;
    font-display: block;
    src: url('/fonts/Arapey-Italic_0.ttf') format('truetype');
  }

  @font-face {
    font-family: 'Nexa Black';
    font-weight: 900;
    font-style: normal;
    font-display: block;
    src: url('/fonts/NexaBlack.otf') format('opentype');
  }

  @font-face {
    font-family: 'MADE Bon Voyage';
    font-weight: 250;
    font-style: normal;
    font-display: block;
    src: url('/fonts/MADE-Bon-Voyage-Thin.otf') format('opentype');
  }

  @font-face {
    font-family: 'MADE Bon Voyage';
    font-weight: 400;
    font-style: normal;
    font-display: block;
    src: url('/fonts/MADE-Bon-Voyage-Regular.otf') format('opentype');
  }
`;
