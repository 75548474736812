import { useEffect, useState } from 'react';

import useWindowSize from 'utils/hooks/useWindowSize';
import { isDesktop, minWindowSize } from 'utils/platform';

const useWindowErrors = () => {
  const windowSize = useWindowSize();
  const [shouldRotate, setRotate] = useState(false);
  const [isTooSmall, setTooSmall] = useState(false);

  useEffect(() => {
    setRotate(!isDesktop() && windowSize.isLandscape);
    setTooSmall(
      isDesktop() &&
        (windowSize.height < minWindowSize.height ||
          windowSize.width < minWindowSize.width)
    );
  }, [windowSize]);

  return { shouldRotate, isTooSmall };
};

export default useWindowErrors;
