import * as React from 'react';

import HeaderLogo from 'components/FilmPage/HeaderLogo';

import theme from 'utils/theme';

import {
  Title,
  Wrapper,
  ContentWrapper,
  Description,
  Container,
  StyledSeparator,
} from '../styled';

const NoWebGL = (): JSX.Element => {
  return (
    <Container>
      <Wrapper>
        <HeaderLogo color={theme.colors.primary.dark} />
        <ContentWrapper>
          <Title>WebGL unsupported</Title>
          <Description>
            Please enable webGL and reload to view the experience.
          </Description>
          <StyledSeparator />
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

NoWebGL.displayName = 'NoWebGL';

export default NoWebGL;
